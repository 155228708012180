import { AnimatePresence, m } from 'framer-motion'
import {
  Col,
  Grid,
  GridContainer,
} from 'd2/components/Grid'
import { MODAL_WIDTH, SPACING_ZERO } from 'd2/constants'
import { isString } from 'lodash-es'
import { memo, useCallback } from 'react'
import { px } from 'd2/utils/style'
import Body from 'd2/components/Typography/Body'
import BodyExtraSmall from 'd2/components/Typography/BodyExtraSmall'
import Box from 'd2/components/Box'
import FaIcon from 'd2/components/FaIcon'
import Flexbox from 'd2/components/Layout/Flexbox'
import Heading2 from 'd2/components/Typography/Heading2'
import HyperLink from 'd2/components/Typography/HyperLink'
import Overlay from 'd2/components/Overlay'
import invariant from 'invariant'
import useModal from 'd2/hooks/useModal'
import useStyles from './styles'
import type { Alignment, Props } from './types'

const CENTER: Alignment = 'center'
const LEFT: Alignment = 'left'
const RIGHT: Alignment = 'right'

const Modal = memo<Props>(({
  alignButton = CENTER,
  alignContent = CENTER,
  alignHeading = CENTER,
  noContentPadding = false,
  noBottomButtonPadding = false,
  alpha,
  bottomButton,
  children,
  content,
  divider,
  dividerBottom,
  footNote,
  footNoteItalic = true,
  heading,
  id,
  inlineHeading,
  maxWidthPx = MODAL_WIDTH,
  modalIndex,
  modalKey,
  onToggleClose,
  overlayRootProps,
  subHeading,
  testID,
}) => {
  const { classes, cx } = useStyles()
  const {
    isOpen,
    modalId,
  } = useModal({
    modalIndex,
    modalKey,
  })
  const onClickAway = useCallback(() => onToggleClose?.(false), [onToggleClose])

  const onClick = useCallback((event: React.SyntheticEvent | null | undefined) => {
    invariant(event, 'event must be a synthetic event. Got %s', event)
    event.preventDefault()
    event.stopPropagation()
    onToggleClose?.(false)
  }, [onToggleClose])

  return (
    <AnimatePresence mode='wait'>
      { !isOpen && modalKey
        ? null
        : (
          <Overlay
            alpha={alpha}
            innerHeight='100%'
            light
            onClickAway={onClickAway}
            rootProps={overlayRootProps}
          >
            <Flexbox
              align='center'
              className={classes.modalWrapper}
              secondaryAlign='center'
            >
              <GridContainer
                className={classes.fullWidth}
                style={{ maxWidth: px(maxWidthPx) }}
                testID={modalId}
              >
                <Grid
                  justifyContent='center'
                >
                  <Col
                    className='modal-clickable-area'
                  >
                    <m.div
                      animate={{
                        opacity: 1,
                        transform: 'scale(1)',
                      }}
                      exit={{
                        opacity: 0,
                        transform: 'scale(0.8)',
                      }}
                      initial={{
                        opacity: 0,
                        transform: 'scale(0.8)',
                      }}
                    >
                      <Grid
                        className={classes.modalContainer}
                        id={id}
                        spacing={SPACING_ZERO}
                      >
                        { onToggleClose && (
                          <Col xs={12}>
                            <Flexbox align='end'>
                              { inlineHeading && heading && (
                                <div
                                  className={cx(classes.headingWrapper, classes.inlineHeading, {
                                    [classes.headingDivider]: divider,
                                    [classes.alignLeft]: alignHeading === LEFT,
                                    [classes.alignCenter]: alignHeading === CENTER,
                                  })}
                                >
                                  <Heading2>
                                    { heading }
                                  </Heading2>
                                </div>) }
                              <HyperLink
                                noFont
                                onClick={onClick}
                                testID={testID && `${testID}-HyperLink-close`}
                              >
                                <FaIcon
                                  className={classes.closeButton}
                                  icon='times'
                                  testID='closeButton'
                                  weight='light'
                                />
                              </HyperLink>
                            </Flexbox>
                          </Col>
                        ) }
                        { !inlineHeading && heading && (
                          <div
                            className={cx(classes.headingWrapper, {
                              [classes.headingDivider]: divider,
                              [classes.alignLeft]: alignHeading === LEFT,
                              [classes.alignCenter]: alignHeading === CENTER,
                            })}
                          >
                            <Heading2>
                              { heading }
                            </Heading2>
                          </div>
                        ) }
                        { children
                        && (<div className={classes.childrenWrapper}>
                          { isString(children)
                            ? <Body>
                              { children }
                            </Body>
                            : children }
                        </div>) }
                        {
                          content && (
                            <div
                              className={cx(classes.content, {
                                [classes.alignLeft]: alignContent === LEFT,
                                [classes.alignCenter]: alignContent === CENTER,
                                [classes.noContentPadding]: noContentPadding,
                              })}
                            >
                              {
                                subHeading
                              && <Flexbox align='center'>
                                <Box my={1}>
                                  <Body>
                                    { subHeading }
                                  </Body>
                                </Box>
                              </Flexbox>
                              }
                              { content }
                            </div>
                          )
                        }

                        { footNote && (
                          <div
                            className={cx(classes.footNote, {
                              [classes.alignLeft]: alignContent === LEFT,
                              [classes.alignCenter]: alignContent === CENTER,
                            })}
                          >
                            <BodyExtraSmall
                              color='gray'
                              variant={footNoteItalic ? 'italic' : 'normal'}
                            >
                              { footNote }
                            </BodyExtraSmall>
                          </div>
                        ) }
                        {
                          bottomButton && (
                            <div
                              className={cx(classes.bottomButton, {
                                [classes.alignLeft]: alignButton === LEFT,
                                [classes.alignCenter]: alignButton === CENTER,
                                [classes.alignRight]: alignButton === RIGHT,
                                [classes.dividerBottom]: dividerBottom,
                                [classes.noContentPadding]: noBottomButtonPadding,
                              })}
                            >
                              { bottomButton }
                            </div>
                          )
                        }
                      </Grid>
                    </m.div>
                  </Col>
                </Grid>
              </GridContainer>
            </Flexbox>
          </Overlay>
        ) }
    </AnimatePresence>
  )
})

Modal.displayName = 'Modal'

export default Modal
