/* eslint-disable @typescript-eslint/no-shadow */
import { AllowedIcons } from 'd2/components/FaIcon/types'
import {
  filter,
  flatten,
  includes,
  isString,
} from 'lodash-es'
import {
  memo,
  useCallback,
  useMemo,
} from 'react'
import Body from 'd2/components/Typography/Body'
import FaIcon from 'd2/components/FaIcon'
import HorizontalSpacer from 'd2/components/HorizontalSpacer'
import ReactSelect, {
  OptionProps,
  SingleValueProps,
  Theme,
  components,
} from 'react-select'
import useTheme from 'd2/hooks/useTheme'
import type { OptionType, Props } from './types'

const Select = memo<Props>(({
  autoHeight,
  borderStyling,
  controlStyling,
  error,
  inHeader = false,
  indicatorStyling,
  isClearable,
  isDisabled,
  isLoading,
  isMulti,
  isSearchable = true,
  menuStyling,
  minWidth,
  name,
  onBlur,
  onChange,
  onFocus,
  onInputChange,
  onItemSelected,
  onMenuScrollToBottom,
  optionStyling,
  options,
  placeholder,
  testID,
  value,
}) => {
  const theme = useTheme()
  const selections = useMemo(
    () => filter(options, ({ value: optionValue }) => includes(flatten([value]), optionValue)),
    [options, value],
  )

  const handleOnChange = useCallback((newSelection: any) => {
    const newSelections = flatten([newSelection || []])
    const newValues = newSelections.map(({ value }) => value)
    onItemSelected?.(newSelection) // only used for async select and NON-multi select
    onChange(isMulti ? newValues : newValues[0])
  }, [isMulti, onChange, onItemSelected])

  const theme2 = useCallback((provided: Theme) => ({
    ...provided,
    colors: {
      ...provided.colors,
      primary: (error ? theme.branding?.danger : theme.branding?.primaryColor) ?? provided.colors.primary!,
    },
  }), [error, theme.branding?.danger, theme.branding?.primaryColor])

  const optionComponent = (props: OptionProps<OptionType>) => (
    <components.Option {...props}>
      { isString(props.data.icon)
        ? <>
          <FaIcon
            icon={props.data.icon}
            weight='regular'
          />
          <HorizontalSpacer half />
        </>
        : props.data.icon }
      { props.data.label }
      { props.data.subText && <>
        { ' (' }
        { props.data.subText }
        )
      </> }
    </components.Option>
  )

  const singleValueComponent = (props: SingleValueProps<OptionType>) => (
    <components.SingleValue {...props}>
      { isString(props.data.icon)
        ? <>
          <FaIcon
            // TODO: Remove 'as' type assertions because they are unsafe.
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            icon={props.data.icon as AllowedIcons}
            weight='regular'
          />
          <HorizontalSpacer half />
        </>
        : props.data.icon }
      { inHeader
        ? <Body style={{ color: theme.branding?.headerTextColor }}>
          { props.data.label }
        </Body>
        : props.data.label }
      { props.data.subText && <>
        { ' (' }
        { props.data.subText }
        )
      </> }
    </components.SingleValue>
  )

  return (
    <ReactSelect
      components={{
        Option: optionComponent,
        SingleValue: singleValueComponent,
      }}
      data-test-id={`react-select-${testID ?? ''}`}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={isMulti}
      isSearchable={isSearchable}
      name={name}
      onBlur={onBlur}
      onChange={handleOnChange}
      onFocus={onFocus}
      onInputChange={onInputChange}
      onMenuScrollToBottom={onMenuScrollToBottom}
      options={options}
      placeholder={placeholder}
      styles={{
        container: (provided) => ({
          ...provided,
          ...minWidth ? { minWidth } : {},
          fontFamily: theme.branding?.primaryFontFamily,
          height: autoHeight ? 'auto' : '2em',
        }),
        control: (provided) => ({
          ...provided,
          ...error
            ? {
              '&:hover': { borderColor: theme.colors.darkRed },
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
              borderColor: theme.branding?.danger,
            }
            : {},
          ...borderStyling || {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: '0px',
            borderWidth: '0px',
            boxShadow: 'none',
            minHeight: '2em',
          },
          ...controlStyling,
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          ...error
            ? {
              '&:hover': { color: theme.colors.darkRed },
              color: theme.branding?.danger,
            }
            : {},
          ...indicatorStyling || {
            padding: '4px',
          },
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 999,
          ...menuStyling,
        }),
        option: (provided, state) => ({
          ...provided,
          '& > span, & > i': {
            color: state.isSelected && theme.palette.common.white,
          },
          ...state.isFocused
            ? {
              '& > i': {
                color: theme.palette.common.white,
              },
            }
            : {},
          color: state.isSelected && theme.palette.common.white,
          ...state.isDisabled
            ? {
              color: theme.branding?.disabledText,
            }
            : {},
          ...optionStyling,
        }),
        placeholder: (provided) => ({
          ...provided,
          ...error ? { color: theme.colors.darkRed } : {},
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: 0,
        }),
      }}
      theme={theme2}
      value={isMulti ? selections : selections[0] ?? null}
    />
  )
})

Select.displayName = 'Select'

export default Select
