/* eslint-disable @typescript-eslint/no-use-before-define */
import { CacheProvider } from '@emotion/react'
import {
  FONT_SIZE_CARD_TITLE_MEDIUM,
  FONT_SIZE_EXTRA_LARGE_ICON,
  FONT_SIZE_LARGE_ICON,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_EXTRA_BOLD,
  FONT_WEIGHT_LIGHT,
  FONT_WEIGHT_NORMAL,
  HEADER_TITLE_LETTER_SPACING,
} from 'd2/constants/fonts'
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles'
import {
  SPACING,
  SPACING_DOUBLE,
  SPACING_HALF,
} from 'd2/constants'
import { Suspense, useMemo } from 'react'
import { constant } from 'lodash-es'
import {
  screenLgMin,
  screenMdMin,
  screenXlMin,
} from 'd2/utils/breakpoints'
import { useBrandingQuery } from 'd2/hooks/useBrandingQuery'
import { useFontLoader } from 'd2/hooks/useFontLoader'
import { useLocation } from 'd2/hooks/useRouter'
import { useMediaQuery } from 'react-responsive'
import CssBaseline from '@mui/material/CssBaseline'
import Suspender from 'react-suspender'
import colors from 'd2/constants/colors'
import createCache, { EmotionCache } from '@emotion/cache'
import isTestEnv from 'd2/utils/isTestEnv'
import light, { getColorObject } from 'd2/themes/light'
import type { BrandingTheme, FontWeightStyles } from 'd2/types'
import type { DynamicProps, StaticProps } from './types'

let muiCache: EmotionCache | undefined
export const createMuiCache = () => {
  muiCache = createCache({
    key: 'mui',
    prepend: true,
  })
  return muiCache
}

const sizing = ({
  GtXl,
  LtLg,
  LtMd,
}: {
  LtMd: boolean,
  GtXl: boolean,
  LtLg: boolean,
}) => ({
  boxParagraphVerticalSpacing: 2,
  bulkActionBarHeight: 88,
  containerGutterSpacing: LtMd ? SPACING : SPACING_DOUBLE,
  containerPaddingBottom: LtMd ? SPACING : SPACING_DOUBLE,
  containerPaddingLeft: GtXl ? SPACING_DOUBLE : LtMd ? SPACING_HALF : SPACING,
  containerPaddingRight: GtXl ? SPACING_DOUBLE : LtMd ? SPACING_HALF : SPACING,
  containerPaddingTop: LtMd ? SPACING : SPACING_DOUBLE,
  expandableInputTransitionDuration: 200,
  graphTabHeaderSize: 12,
  graphTabLabelSize: 16,
  graphTabValueSize: 24,
  gridContainerPaddingBottom: GtXl ? SPACING_DOUBLE : LtMd ? SPACING_HALF : SPACING,
  gridContainerPaddingLeft: GtXl ? SPACING_DOUBLE : LtMd ? SPACING_HALF : SPACING,
  gridContainerPaddingRight: GtXl ? SPACING_DOUBLE : LtMd ? SPACING_HALF : SPACING,
  gridContainerPaddingTop: GtXl ? SPACING_DOUBLE : LtMd ? SPACING_HALF : SPACING,
  mainHeaderFilterBarHeight: LtMd ? 90 : 70,
  mainHeaderNavigationTabsBarHeight: 63,
  navbarTabHeight: 4,
  paragraphVerticalSpacing: 16,
  scrollableNavigationTabs: LtLg,
  searchBarHeight: 36,
  searchFieldHeight: LtMd ? 39 : 48,
  sideNavItemHeight: 25,
  socialIconSize: FONT_SIZE_LARGE_ICON,
  stackedMainHeaderTitle: {
    letterSpacing: HEADER_TITLE_LETTER_SPACING,
    overflow: 'hidden',
    textOverflow: 'clip',
    whiteSpace: 'normal',
  },
  uploadIconSize: FONT_SIZE_EXTRA_LARGE_ICON,
  videoTypeIconSize: FONT_SIZE_CARD_TITLE_MEDIUM,
} as const)

export type Sizing = $Call1<typeof sizing, {
  LtMd: boolean,
  GtXl: boolean,
  LtLg: boolean,
}>

// This one does query
export const ThemeProvider = ({
  children,
  generateClassName,
  isTddRoot: isTddRootProp,
}: DynamicProps) => {
  const location = useLocation()
  const isTddRoot = useMemo(
    () => typeof isTddRootProp === 'boolean'
      ? isTddRootProp
      : location.pathname === '/d2/tdd' || location.pathname === '/d2/tdd/blank_partial_route',
    [isTddRootProp, location],
  )

  const [branding] = useBrandingQuery({
    batchKey: 'ThemeProvider',
    skip: !!isTddRoot,
  })

  useFontLoader()

  return (<StaticThemeProvider
    branding={branding}
    generateClassName={generateClassName}
    isTddRoot={isTddRoot}
  >
    { children }
  </StaticThemeProvider>)
}

// This one does not do a query
export const StaticThemeProvider = ({
  branding,
  children,
  isTddRoot = false,
}: StaticProps) => {
  // The commented out ones are unused, but if needed simply uncomment them.
  // const GtSm: boolean = useMediaQuery({ minWidth: screenSmMin })
  const GtMd: boolean = useMediaQuery({ minWidth: screenMdMin })
  const GtLg: boolean = useMediaQuery({ minWidth: screenLgMin })
  const GtXl: boolean = useMediaQuery({ minWidth: screenXlMin })
  const LtLg = !GtLg
  const LtMd = !GtMd
  // const LtSm = !GtSm
  // const LtXl = !GtXl

  const [theme, ready] = useMemo(
    () => {
      const baseTheme = {
        ...isTestEnv
          ? {
            // Disable animations in tests. Because they are timing-dependent, they can cause flakiness in snapshots.
            components: {
              MuiInputLabel: {
                defaultProps: {
                  disableAnimation: true,
                  shrink: false,
                },
              },
            },
            // https://github.com/mui-org/material-ui/issues/10560#issuecomment-439147374
            transitions: { create: constant('none') },
          }
          : null,
      }

      if (isTddRoot) {
        return [
          createTheme({
            ...baseTheme,
            ...sizing({
              GtXl,
              LtLg,
              LtMd,
            }),
            // TODO: Is it important to fill this with placeholder data for TDD Root?
            // @ts-expect-error Object literal may only specify known properties, and 'branding' does not exist in type 'ThemeOptions'.ts(2345)
            branding: undefined,
            colors,
          }),
          true,
        ]
      }

      if (!branding) {
        return [
          createTheme({
            ...baseTheme,
            ...sizing({
              GtXl,
              LtLg,
              LtMd,
            }),
            // TODO: Is it important to fill this with placeholder data during Suspense time?
            // @ts-expect-error Object literal may only specify known properties, and 'branding' does not exist in type 'ThemeOptions'.ts(2345)
            branding: undefined,
            colors,
          }),
          false,
        ]
      }

      const {
        application_url: applicationUrl,
        custom_terms_of_use_url: customTermsOfUseUrl,
        dashboard_url: dashboardUrl,
        error_color: errorColor,
        has_open_signup: hasOpenSignup,
        header_background_color: headerBackgroundColor,
        header_tab_text_color: headerTabTextColor,
        header_tab_text_selected_color: headerTabTextSelectedColor,
        info_color: infoColor,
        is_whitelabel: isWhitelabel,
        logo_url_default: logoUrlDefault,
        logo_url_no_text: logoUrlNoText,
        logo_url_sign_in: logoUrlSignIn,
        logo_url_square: logoUrlSquare,
        logo_url_white_text: logoUrlWhiteText,
        name,
        nav_logo_url: navLogoUrl,
        primary_color: primaryColor,
        primary_font_fallback: primaryFontFallback,
        primary_font_family: primaryFontFamily,
        primary_font_name: primaryFontName,
        primary_font_url: primaryFontUrl,
        primary_gradient_end: primaryGradientEnd,
        primary_gradient_start: primaryGradientStart,
        request_invite_text: requestInviteText,
        secondary_color: secondaryColor,
        secondary_font_fallback: secondaryFontFallback,
        secondary_font_family: secondaryFontFamily,
        secondary_font_name: secondaryFontName,
        secondary_font_url: secondaryFontUrl,
        secondary_gradient_end: secondaryGradientEnd,
        secondary_gradient_start: secondaryGradientStart,
        sign_in_additional_image_one_max_width_px: signInAdditionalImageOneMaxWidthPx,
        sign_in_additional_image_one_url: signInAdditionalImageOneUrl,
        sign_in_additional_image_two_max_width_px: signInAdditionalImageTwoMaxWidthPx,
        sign_in_additional_image_two_url: signInAdditionalImageTwoUrl,
        sign_in_background_image_url: signInBackgroundImageUrl,
        sign_in_carousel_text_color: signInCarouselTextColor,
        sign_in_custom_background_color: signInCustomBackgroundColor,
        sign_in_custom_tagline: signInCustomTagline,
        sign_in_gradient_end: signInGradientEnd,
        sign_in_gradient_start: signInGradientStart,
        sign_in_logo_max_width_px: signInLogoMaxWidthPx,
        sign_in_tagline_text_color: signInTaglineTextColor,
        sign_in_template: signInTemplate,
        success_color: successColor,
        support_url: supportUrl,
        tertiary_color: tertiaryColor,
        warning_color: warningColor,
      } = branding

      const lightTheme = light(branding)

      const primary = getColorObject(primaryColor)
      const secondary = getColorObject(secondaryColor)
      const tertiary = getColorObject(tertiaryColor)
      const error = getColorObject(errorColor)
      const success = getColorObject(successColor)
      const warning = getColorObject(warningColor)
      const yellow = getColorObject(colors.yellow)
      const info = getColorObject(infoColor)
      const gray = getColorObject(colors.gray)
      const white = getColorObject(colors.white)
      const faintGray = getColorObject(colors.faintGray)
      const headerBackgroundColorObject = getColorObject(headerBackgroundColor)

      const primaryGradient = buildGradient(primaryGradientStart, primaryGradientEnd)
      const secondaryGradient = buildGradient(secondaryGradientStart, secondaryGradientEnd)

      const brandingTheme: BrandingTheme = {
        alternateBackgroundColor: '#F7F7F7',
        applicationUrl,
        checkboxListBorder: gray['200'],
        customTermsOfUseUrl,
        danger: error.main,
        dangerActive: error.light,
        dangerHover: error.dark,
        dashboardGraphPalette: [
          colors.appleMusicBlue,
          colors.cerise,
          colors.manz,
          colors.youtubeRed,
          colors.youtubeGray,
          colors.darkGreen,
          colors.darkBlue,
          colors.purple,
          colors.selectiveYellow,
          colors.persianRed,
          colors.japaneseLaurel,
          colors.governorBay,
          colors.electricViolet,
          colors.sunglow,
          colors.radicalRed,
          colors.green,
          colors.toreaBay,
          colors.neonViolet,
          colors.webOrange,
          colors.buddhaGold,
          colors.darkGray,
        ],
        dashboardUrl,
        defaultTextColor: colors.black,
        disabled: gray['200'],
        disabledText: gray['500'],
        grayText: gray['700'],
        hasOpenSignup,
        headerBackgroundColor,
        headerGradient: secondaryGradient,
        headerGradientEnd: secondaryGradientEnd,
        headerGradientStart: secondaryGradientStart,
        headerTabTextColor,
        headerTabTextSelectedColor,
        headerTextColor: headerBackgroundColorObject.contrastText,
        highlight: yellow.main,
        highlightActive: yellow.light,
        highlightHover: yellow.dark,
        hoverColor: faintGray['100'],
        info: info.main,
        infoActive: info.light,
        infoHover: info.dark,
        isWhitelabel,
        lightestGrayBorder: gray['100'],
        lightestGrayChart: gray['50'],
        linkActive: primary.light,
        linkColor: primary.main,
        linkHover: primary.dark,
        logoUrlDefault,
        logoUrlNoText,
        logoUrlSignIn,
        logoUrlSquare,
        logoUrlWhiteText,
        name,
        navLogoUrl,
        navOrgLinkMobileColor: secondary.isDark ? secondary.light : secondary.main,
        primaryActive: primary.light,
        primaryButtonTextColor: primary.contrastDefaultColor === 'light' ? colors.white : colors.black,
        primaryColor: primary.main,
        primaryContrastTextColor: primary.contrastDefaultColor === 'light' ? colors.white : colors.black,
        primaryFocusBorder: primary.dark,
        primaryFontFallback,
        primaryFontFamily,
        primaryFontName,
        primaryFontUrl,
        primaryGradient,
        primaryGradientEnd,
        primaryGradientStart,
        primaryHover: primary.dark,
        primaryInvertedHover: primary['50'],
        requestInviteText,
        secondaryActive: secondary.light,
        secondaryButtonTextColor: secondary.contrastDefaultColor === 'light' ? colors.white : colors.black,
        secondaryColor: secondary.main,
        secondaryFocusBorder: secondary.dark,
        secondaryFontFallback,
        secondaryFontFamily,
        secondaryFontName,
        secondaryFontUrl,
        secondaryGradient,
        secondaryGradientEnd,
        secondaryGradientStart,
        secondaryHover: secondary.dark,
        signInAdditionalImageOneMaxWidthPx,
        signInAdditionalImageOneUrl,
        signInAdditionalImageTwoMaxWidthPx,
        signInAdditionalImageTwoUrl,
        signInBackgroundImageUrl,
        signInCarouselTextColor,
        signInCustomBackgroundColor,
        signInCustomTagline,
        signInGradientEnd,
        signInGradientStart,
        signInLogoMaxWidthPx,
        signInTaglineTextColor,
        signInTemplate,
        success: success.main,
        successActive: success.light,
        successHover: success.dark,
        supportUrl,
        tertiaryActive: tertiary.light,
        tertiaryColor: tertiary.main,
        tertiaryHover: tertiary.dark,
        userAvatarColors: [
          primary['100'],
          primary['500'],
          primary['900'],
          secondary['100'],
          secondary['500'],
          secondary['900'],
          tertiary['100'],
          tertiary['500'],
          tertiary['900'],
        ],
        warning: warning.main,
        warningActive: warning.light,
        warningHover: warning.dark,
        white: white.main,
        whiteColor: white.main,

      }

      const fontStyles: FontWeightStyles = {
        fontBoldStyles: {
          fontWeight: FONT_WEIGHT_BOLD,
        },
        fontExtraBoldStyles: {
          fontWeight: FONT_WEIGHT_EXTRA_BOLD,
        },
        fontLightStyles: {
          fontWeight: FONT_WEIGHT_LIGHT,
        },
        fontNormalStyles: {
          fontWeight: FONT_WEIGHT_NORMAL,
        },
      }

      return [
        createTheme({
          ...baseTheme,
          ...lightTheme,
          ...fontStyles,
          ...sizing({
            GtXl,
            LtLg,
            LtMd,
          }),
          // @ts-expect-error Object literal may only specify known properties, and 'branding' does not exist in type 'ThemeOptions'.ts(2345)
          branding: brandingTheme,
          colors,
        }),
        true,
      ]
    },
    [
      GtXl,
      LtLg,
      LtMd,
      branding,
      isTddRoot,
    ],
  )

  return (
    <CacheProvider value={muiCache ?? createMuiCache()}>
      <MuiThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback="">
              { !ready && <Suspender /> }
              { children }
            </Suspense>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </MuiThemeProvider>
    </CacheProvider>
  )
}

function buildGradient (start: string, end: string): string {
  return `linear-gradient(135deg, ${start} 0%, ${end} 100%)`
}
