import { Flexbox } from 'd2/components/Layout'
import { isBoolean, isNil } from 'lodash-es'
import {
  memo,
  useCallback,
  useMemo,
} from 'react'
import { useFieldValue } from '../FieldValue/hook'
import Checkbox from '@mui/material/Checkbox'
import FaIcon from 'd2/components/FaIcon'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputContainer from '../InputContainer'
import Switch from 'd2/components/Switch'
import Tooltip from 'd2/components/Tooltip'
import invariant from 'invariant'
import useStyles from './styles'
import type { FieldBaseProps, FieldLabelBasicPosition } from '../types'
/*

oneWay: if true, the form values won't be automatically updated when the value changes
*/
export type OwnProps = FieldBaseProps & {
  checkboxTop?: boolean,
  className?: string,
  forceChecked?: boolean,
  indeterminate?: boolean,
  indeterminateWhenNil?: boolean,
  labelPosition?: FieldLabelBasicPosition,
  onChange?: (a: boolean) => void,
  oneWay?: boolean,
  secondary?: boolean,
  testID?: string,
  tooltipText?: string,
  variant?: 'checkbox' | 'switch'
}

export type Props = OwnProps

const CheckboxField = memo<Props>(({
  boldLabel = false,
  checkboxTop,
  className,
  disabled,
  errorKey,
  errorKeys,
  errorPlacement,
  forceChecked,
  fullWidth,
  helperText,
  id,
  indeterminate,
  indeterminateWhenNil,
  label,
  labelPosition,
  onChange: onChangeProp,
  oneWay,
  path,
  required,
  resourceId,
  resourceType,
  secondary,
  tooltipText,
  variant = 'checkbox',
}) => {
  const { classes, cx } = useStyles()
  const {
    error,
    fieldTestID,
    isDisabled,
    onChange,
    value,
  }: any = useFieldValue({
    disabled,
    errorKey,
    errorKeys,
    path,
    resourceId,
    resourceType,
  })
  invariant(isNil(value) || isBoolean(value), 'Value for CheckboxField with path %s is not a boolean. Is it %s. Value: %s', path, typeof value, value)
  const actualLabelPosition: FieldLabelBasicPosition = labelPosition ?? 'right'

  const handleOnChange = useCallback(({ target: { checked } }: {
    target: {
      checked: boolean
    }
  }) => {
    if (typeof onChangeProp === 'function') onChangeProp(checked)

    if (!oneWay) {
      onChange(checked)
    }
  }, [onChange, onChangeProp, oneWay])

  const handleSwitchChange = useCallback((checked: boolean) => {
    if (typeof onChangeProp === 'function') {
      onChangeProp(checked)
    } else {
      onChange(checked)
    }
  }, [onChangeProp, onChange])

  const formattedLabel = useMemo(() => {
    if (tooltipText) {
      return (
        <Flexbox
          secondaryAlign='center'
        >
          { label }
          <Tooltip
            className={classes.tooltipContainer}
            hasArrow
            title={tooltipText}
          >
            <span
              className={classes.tooltip}
            >
              <FaIcon
                icon='info-circle'
                weight='solid'
              />
            </span>
          </Tooltip>
        </Flexbox>
      )
    }

    return label
  }, [
    classes.tooltip,
    classes.tooltipContainer,
    label,
    tooltipText,
  ])

  const checkboxClassOverrides = useMemo(() => ({ root: actualLabelPosition === 'right' ? classes.checkboxWithRightLabel : labelPosition === 'left' ? classes.checkboxWithLeftLabel : classes.checkboxWithTopLabel }), [classes, labelPosition, actualLabelPosition])

  return (
    <InputContainer
      boldLabel={boldLabel}
      className={className}
      disabled={isDisabled}
      error={error}
      errorPlacement={errorPlacement ?? 'helpText'}
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      labelPosition='topOrPlaceholder'
      required={required}
    >
      <FormControlLabel
        className={cx(classes.label, {
          [classes.labelOnLeft]: actualLabelPosition === 'left',
          [classes.labelOnRight]: actualLabelPosition === 'right',
          [classes.labelOnTop]: actualLabelPosition === 'top',
          [classes.labelOnTopCenter]: actualLabelPosition === 'topCenter',
          [classes.checkboxTop]: checkboxTop,
        })}
        control={variant === 'checkbox'
          ? <Checkbox
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            checked={forceChecked || !!value}
            classes={checkboxClassOverrides}
            color='primary'
            data-test-id={fieldTestID}
            indeterminate={!!indeterminate || (indeterminateWhenNil && isNil(value))}
            onChange={handleOnChange}
          />
          : <Switch
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            checked={forceChecked || !!value}
            onChange={handleSwitchChange}
            secondary={secondary}
            testID={fieldTestID || `${path}-Form-Switch`}
          />}
        label={formattedLabel}
      />
    </InputContainer>
  )
})

CheckboxField.displayName = 'CheckboxField'

export default CheckboxField
