import {
  MAX,
  SPACING_QUARTER,
  SPACING_ZERO,
} from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles, { CSSObject } from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const CHECKBOX_HORIZONTAL_MARGIN = 8
const CHECKBOX_LABEL_SIZE = 14
const INFO_ICON_FONT_SIZE = 12
const INFO_ICON_MARGIN_LEFT = 4

export const styles = (theme: MergedThemes) => {
  const checkbox = {
    height: 'auto', // override material UI's 48px widths
    padding: SPACING_ZERO,
    width: 'auto',
  } as const satisfies CSSObject
  return {
    checkbox,
    checkboxTop: {
      alignItems: 'flex-start',
    },
    checkboxWithLeftLabel: {
      ...checkbox,
      marginLeft: px(CHECKBOX_HORIZONTAL_MARGIN),
    },
    checkboxWithRightLabel: {
      ...checkbox,
      marginRight: px(CHECKBOX_HORIZONTAL_MARGIN),
    },
    checkboxWithTopLabel: {
      ...checkbox,
      marginTop: px(SPACING_QUARTER),
    },
    label: {
      color: theme.colors.darkGray,
      fontSize: px(CHECKBOX_LABEL_SIZE),
      marginLeft: 0,
      marginRight: 0,
      minWidth: 0,
    },
    labelOnLeft: {
      flexDirection: 'row-reverse',
    },
    labelOnRight: {
      flexDirection: 'row',
    },
    labelOnTop: {
      alignItems: 'flex-start',
      flexDirection: 'column-reverse',
    },
    labelOnTopCenter: {
      alignItems: 'center',
      flexDirection: 'column-reverse',
      width: MAX,
    },
    tooltip: {
      cursor: 'pointer',
      fontSize: px(INFO_ICON_FONT_SIZE),
      marginLeft: px(INFO_ICON_MARGIN_LEFT),
      pointerEvents: 'all',
    },
    tooltipContainer: {
      alignItems: 'center',
      display: 'flex',
    },
  } as const
}

export default makeStyles({
  name: 'CheckboxField',
})(styles)
