import { forwardRef, memo } from 'react'
import { useIsMobile } from '../../Responsive'
import IconButton from '../IconButton'
import useStyles from './styles'
import type { BlankButtonProps } from './types'

const BlankButton = memo<BlankButtonProps>(forwardRef(
  ({
    className,
    color,
    disableMobile = true,
    maxWidth,
    noPadding,
    ...props
  }, ref) => {
    const { classes, cx } = useStyles()
    const isMobile = useIsMobile()

    return (
      <IconButton
        {...props}
        className={cx({
          [classes.darkGray]: color === 'darkGray',
          [classes.lightGray]: color === 'lightGray',
          [classes.danger]: color === 'danger',
          [classes.paddingNone]: noPadding,
          [classes.primary]: color === 'primary',
          [classes.secondary]: color === 'secondary',
          [classes.success]: color === 'success',
          [classes.white]: color === 'white',
          [classes.tertiary]: color === 'tertiary',
        }, classes.blank, className)}
        maxWidth={maxWidth || (!disableMobile && isMobile)}
        noPadding={noPadding}
        ref={ref}
        variant='text'
      />
    )
  },
))

BlankButton.displayName = 'BlankButton'

export default BlankButton
