import { SPACING_ZERO } from 'd2/constants'
import { allLinkStates } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = ({ branding, colors }: MergedThemes) => ({
  blank: {
    ...allLinkStates({
      backgroundColor: colors.transparent,
      borderColor: colors.transparent,
      boxShadow: 'none',
    }),
    backgroundColor: colors.transparent,
    borderColor: colors.transparent,
    borderRadius: SPACING_ZERO,
    minHeight: SPACING_ZERO,
    minWidth: SPACING_ZERO,
    textTransform: 'none',
  },
  danger: {
    '&:active': {
      color: branding?.dangerActive,
    },
    '&:disabled': {
      color: branding?.disabledText,
    },
    '&:hover': {
      color: branding?.dangerHover,
    },
    '&:link': {
      color: branding?.danger,
    },
    '&:visited': {
      color: branding?.danger,
    },
    color: branding?.danger,
  },
  darkGray: {
    ...allLinkStates({
      color: colors.darkGray,
    }),
  },
  lightGray: {
    ...allLinkStates({
      color: colors.lightGray,
    }),
  },
  paddingNone: {
    padding: SPACING_ZERO,
  },
  primary: {
    '&:focus': {
      outlineOffset: SPACING_ZERO,
    },
    ...allLinkStates({
      color: branding?.primaryColor,
    }),
  },
  secondary: {
    '&:focus': {
      outlineOffset: SPACING_ZERO,
    },
    ...allLinkStates({
      color: branding?.secondaryColor,
    }),
  },
  success: {
    ...allLinkStates({
      color: branding?.successActive,
    }),
  },
  tertiary: {
    ...allLinkStates({
      color: branding?.tertiaryActive,
    }),
  },
  white: {
    ...allLinkStates({
      color: branding?.white,
    }),
  },
} as const)

export default makeStyles({
  name: 'BlankButton',
})(styles)
