import { buildNestedQuery } from 'd2/utils/Routes'
import {
  debounce,
  includes,
  isArray,
  isNaN,
  isNil,
  omit,
} from 'lodash-es'
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'd2/hooks/useRouter'
import invariant from 'invariant'
import isTestEnv from 'd2/utils/isTestEnv'
import qs from 'qs'

/*

useUrlQueryParam
--------------------

Provides a value and change props for a particular query string parameter.

*/

type TOptions = {
  method: 'push' | 'replace'
}

type TQueryParams = {
  readonly [x: string]: unknown
}

type TSetState<TArg> = (c?: TArg | null, b?: TQueryParams | null, a?: TOptions | null) => TQueryParams
export type TReturn<TArg> = [TArg, TSetState<TArg>]

// TODO: Unit test the coerce function individually.

export function coerceInt (value: any): number {
  const parsed: number = parseInt(value, 10) // eslint-disable-line unicorn/prefer-number-properties
  if (isNaN(parsed)) {
    return 0
  }

  return parsed
}

// ts-prune-ignore-next - May be used eventually
export function coerceIntNullable (value: any): number | null | undefined {
  const parsed: number = parseInt(value, 10) // eslint-disable-line unicorn/prefer-number-properties
  if (isNaN(parsed)) {
    return null
  }

  return parsed
}

export function coerceString (value: unknown): string {
  if (isNil(value)) return ''
  return String(value)
}

export function coerceStringNullable (value: unknown): string | null | undefined {
  if (isNil(value)) return null
  return String(value)
}

export function coerceStringEnum<TEnum extends string> (value: any, values: TEnum[]): TEnum {
  if (includes(values, value)) return value
  const firstValue = values[0]
  invariant(firstValue, 'coerceStringEnum 2nd arg values should not be empty. Got %s', firstValue)

  return firstValue
}

// ts-prune-ignore-next - May be used eventually
export function coerceStringEnumNullable<TEnum extends string> (value: any, values: TEnum[]): TEnum | null | undefined {
  if (includes(values, value)) return value
  return null
}

export function coerceBoolean (value: unknown): boolean {
  if (isNil(value)) return false
  return value === 'true' || value === '1'
}

export function coerceBooleanDefaultTrue (value: unknown): boolean {
  if (isNil(value)) return true
  return value === 'true' || value === '1'
}

// ts-prune-ignore-next - May be used eventually
export function coerceBooleanNullable (value: unknown): boolean | null | undefined {
  if (isNil(value)) return null
  return value === 'true' || value === '1'
}

export function coerceObject (value: unknown): Partial<TQueryParams> {
  if (!value || typeof value !== 'object') return {}

  return value
}

export function coerceArray<T = unknown> (value: any): Array<T> {
  if (!value) return []
  if (!isArray(value)) return [value]

  return value
}

export function coerceObjectNullable (value: unknown): Partial<TQueryParams> | null | undefined {
  if (!value || typeof value !== 'object') return null

  return value
}

// the type `mixed` is important instead of `any` here because flow does not seem to care whether or not we typecheck when using `any`.
// https://github.com/facebook/react/blob/42794557ca44a8c05c71aab698d44d1294236538/packages/react/src/ReactHooks.js#L98
export function useUrlQueryParam<TArg> (name: string, reduceParam: (a: unknown) => TArg, debounceMs = 0): TReturn<TArg> {
  debounceMs = isTestEnv ? 0 : debounceMs
  const navigate = useNavigate()

  // Offer debounced setState that optimistically tracks local state in a ref and writes to URL after the user stops typing.
  // The debounce is only really useful for text input fields, so you can type without experiencing extreme lag and delay.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const debouncedNavigate = useMemo(() => debounce(navigate, debounceMs) as any as typeof navigate, [navigate, debounceMs])

  const location = useLocation()
  const { search } = location

  const queryParams: TQueryParams = useMemo(() => qs.parse(search, {
    arrayLimit: 100, // qs default is 20 and was making arrays with more than 20 items to parse into an object instead of an array
    ignoreQueryPrefix: true,
  }), [search])
  const { [name]: value } = queryParams

  const currentState: TArg = useMemo(() => reduceParam(value), [reduceParam, value])
  const [localCurrentState, setLocalCurrentState] = useState<TArg>(currentState)

  useEffect(() => {
    setLocalCurrentState(currentState)
  }, [currentState])

  const setState: TSetState<TArg> = useCallback((input?: TArg | null, additionalParams: TQueryParams | null | undefined = null, options: TOptions | null | undefined = null) => {
    let newParams: TQueryParams = isNil(input)
      ? omit(queryParams, name)
      : {
        ...queryParams,
        [name]: input,
      }

    newParams = {
      ...newParams,
      ...additionalParams,
    }

    const newLocation = {
      ...location,
      key: undefined,
      search: `?${buildNestedQuery(newParams)}`,
    }

    setLocalCurrentState(reduceParam(input))

    const navFunction = debounceMs === 0 ? navigate : debouncedNavigate
    if (options?.method === 'replace') {
      navFunction(newLocation, { replace: true })
    } else {
      navFunction(newLocation)
    }

    return newParams
  }, [
    debounceMs,
    debouncedNavigate,
    location,
    name,
    navigate,
    queryParams,
    reduceParam,
  ])

  return useMemo(
    () => [localCurrentState, setState],
    [localCurrentState, setState],
  )
}
